<template>
  <section>
    <b-card body-class="p-0">
      <template #header>
        <div class="d-flex align-items-center">
          <span class="text-primary">Newsletter </span>
          <b-breadcrumb class="ml-2">
            <b-breadcrumb-item>
              <feather-icon
                icon="HomeIcon"
                scale="1.25"
                shift-v="1.25"
              ></feather-icon>
            </b-breadcrumb-item>
            <b-breadcrumb-item active> Crea Newsletter</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </template>

      <form-wizard
        ref="createCampaignWizard"
        color="#377DFF"
        :title="null"
        :subtitle="null"
        shape="square"
        class="custom shadow-none"
        next-button-text="Avanti"
        back-button-text="Indietro"
      >
        <tab-content
          title="Nome Campagna"
          :before-change="validateCampaignName"
        >
          <b-row>
            <b-col md="6">
              <h3 class="text-primary-light">Nome Campagna</h3>

              <p class="my-2">
                Mantieni i tuoi iscritti impegnati condividendo le tue ultime
                notizie, <br />
                promuovendo una linea di prodotti o annunciando un evento.
              </p>

              <validation-observer ref="campaignNameValidation">
                <b-form-group label="Nome campagna">
                  <validation-provider
                    #default="{ errors }"
                    name="Nome Campagna"
                    rules="required"
                  >
                    <b-form-input
                      :state="errors.length > 0 ? false : null"
                      placeholder="Inserisci il nome della tua campagna..."
                      v-model="campaign_name"
                    />
                    <small class="text-danger"> {{ errors[0] }} </small>
                  </validation-provider>
                </b-form-group>
              </validation-observer>
            </b-col>
          </b-row>
        </tab-content>

        <tab-content title="Dest./Mitt./Ogg.">
          <h3 class="text-primary-light">{{ campaign_name }}</h3>

          <div class="d-flex justify-content-between align-items-center my-3">
            <div>
              <h4 class="text-primary-light">Destinatari</h4>
              <p>A chi stai inviando questa campagna?</p>
            </div>

            <div class="w-25">
              <b-button block variant="primary-light" @click="toggleRecipients">
                Seleziona Destinatari
              </b-button>
            </div>
          </div>

          <div v-show="showRecipients">
            <b-row>
              <b-col md="6">
                <b-form-group label="Lista destinatari">
                  <v-select :options="recipients" label="list_name" />
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group label="Segmentazione, Tag o Elenchi filtrati">
                  <b-form-select v-model="list_filter" small>
                    <b-form-select-option-group label="Opzioni Liste Dinamiche">
                      <b-form-select-option value="1"
                        >Tutte le email presenti nella
                        lista</b-form-select-option
                      >
                      <b-form-select-option value="2"
                        >Integra o rimuovi indirizzi alla
                        lista</b-form-select-option
                      >
                    </b-form-select-option-group>
                    <b-form-select-option-group label="Tag">
                      <b-form-select-option disabled :value="null"
                        >Nessun Tag Presente</b-form-select-option
                      >
                    </b-form-select-option-group>
                    <b-form-select-option-group label="Elenchi Filtrati">
                      <b-form-select-option disabled :value="null"
                        >Nessun Elenco Filtrato Presente</b-form-select-option
                      >
                    </b-form-select-option-group>
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="my-2" v-if="list_filter == '2'">
              <b-col md="12">
                <h3 class="text-primary-light">Nuovo Segmento</h3>

                <p>
                  La creazione di un segmento permette di inviare le email ad
                  uno specifico Target
                </p>
              </b-col>

              <b-col md="6">
                <h4 class="text-primary-light">
                  Includi questi indirizzi (uno per riga):
                </h4>

                <b-form-textarea rows="8"> </b-form-textarea>
              </b-col>

              <b-col md="6">
                <h4 class="text-primary-light">
                  Escludi questi indirizzi (uno per riga):
                </h4>

                <b-form-textarea rows="8"> </b-form-textarea>
              </b-col>
            </b-row>
          </div>

          <div class="d-flex justify-content-between align-items-center my-3">
            <div>
              <h4 class="text-primary-light">Mittente</h4>
              <p>Chi sta inviando questa campagna?</p>
            </div>

            <div class="w-25">
              <b-button block variant="primary-light" @click="toggleSender">
                Seleziona Mittente
              </b-button>
            </div>
          </div>

          <div v-if="showSender">
            <b-row>
              <b-col md="6">
                <v-select :options="senders" label="sender_name">
                    <template #selected-option="{sender_name, sender_email}">
                        <span>{{sender_name}} ({{sender_email}})</span>
                    </template>
                    <template #option="{sender_name, sender_email}">
                        <span>{{sender_name}} ({{sender_email}})</span>
                    </template>
                </v-select>
              </b-col>
            </b-row>
          </div>

          <div class="d-flex justify-content-between align-items-center my-3">
            <div>
              <h4 class="text-primary-light">Oggetto</h4>
              <p>Qual è l'oggetto di questa campagna?</p>
            </div>

            <div class="w-25">
              <b-button block variant="primary-light" @click="toggleSubject">
                Imposta Oggetto
              </b-button>
            </div>
          </div>

          <div v-if="showSubject">
            <b-row>
              <b-col md="6">
                <b-form-input
                  placeholder="Inserisci l'oggetto della campagna..."
                >
                </b-form-input>
              </b-col>
            </b-row>
          </div>

          <div class="d-flex justify-content-between align-items-center my-3">
            <div>
              <h4 class="text-primary-light">Contenuto</h4>
              <p>Progetta il contenuto per la tua e-mail</p>
            </div>

            <div class="w-25">
              <b-button block variant="primary-light">
                Seleziona Template
              </b-button>
            </div>
          </div>
        </tab-content>

        <tab-content title="Scelta template">
          <b-row>
            <b-col md="3" v-for="template in templates" :key="template.id_layout">
              <b-card>
                <div
                  class="border border-primary-light rounded-lg"
                  style="height: 200px"
                ></div>

                <p class="my-1 mb-3">{{template.layout_name}}</p>

                <div class="d-flex justify-content-between align-items-center">
                  <feather-icon icon="EyeIcon" size="25" />
                  <b-button variant="primary-light">Usa template</b-button>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </tab-content>

        <tab-content title="Anteprima e-mail"> </tab-content>

        <tab-content title="Campagna salvata"> </tab-content>
      </form-wizard>
    </b-card>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BBreadcrumb,
  BBreadcrumbItem,
  BCard,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
  BFormSelectOptionGroup,
  BFormTextarea,
} from "bootstrap-vue";

import { ValidationObserver, ValidationProvider, localize } from "vee-validate";
import { required, email } from "@validations";

import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

import { Requests } from "@/api";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
    BFormGroup,
    BFormSelect,
    BFormSelectOption,
    BFormSelectOptionGroup,
    BFormInput,
    BFormTextarea,
    FormWizard,
    TabContent,
    vSelect,
    ValidationObserver,
    ValidationProvider,
  },

  data: () => ({
    campaign_name: "",
    list_filter: "",
    recipients: [],
    senders: [],
    templates: [],

    //Helpers

    showRecipients: false,
    showSender: false,
    showSubject: false,
    required,
    email,
  }),

  created() {
    localize("it");
    this.getRecipientList();
    this.getSendersList();
    this.getTemplatesList();
  },

  methods: {
    toggleRecipients() {
      this.showRecipients = !this.showRecipients;
    },

    toggleSender() {
      this.showSender = !this.showSender;
    },

    toggleSubject() {
      this.showSubject = !this.showSubject;
    },

    validateCampaignName() {
      return new Promise((resolve, reject) => {
        this.$refs.campaignNameValidation.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },

    async getRecipientList() {
      this.loading = true;

      try {
        const response = await Requests.getRecipientList();
        this.recipients = response;
      } catch (err) {
        console.debug(err);
      }

      this.loading = false;
    },

    async getSendersList() {
      this.loading = true;

      try {
        const response = await Requests.getSendersList();
        this.senders = response;
      } catch (err) {
        console.debug(err);
      }

      this.loading = false;
    },

    async getTemplatesList() {
      this.loading = true;

      try {
        const response = await Requests.getTemplatesList();
        this.templates = response;
      } catch (err) {
        console.debug(err);
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
</style>
